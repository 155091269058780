<template>
  <div class="card shadow p-2">
    <b-card-code :title="title" no-body>
      <b-card-body>
        <div class="row justify-content-center">
          <div class="col-lg-12">
            <div
              v-if="loading"
              class="spinner-border text-primary"
              role="status"
            >
              <span class="sr-only">Loading...</span>
            </div>
            <form v-else id="form" @submit.prevent="UpdateGeneralSetting">
              <div class="row">
                <div class="col-lg-6">
                  <div class="fom-group">
                    <label for="">Site Name</label>
                    <input
                      v-model="form.site_name"
                      type="text"
                      class="form-control"
                      name="site_name"
                    />
                  </div>
                </div>
                <div class="col-lg-6">
                  <!-- <div
                v-if="site_logo"
                class="form-group"
              >
                <img
                  :src="site_logo"
                  style="    max-width: 200px;
    border: 4px solid #7367f0;
    border-radius: 10px;"
                  alt=""
                >
              </div> -->

                  <div class="form-group">
                    <div class="row">
                      <div class="col-lg-6">
                        <label for="">Site Logo </label>
                        <input
                          id="file"
                          type="file"
                          class="form-control"
                          @change="uploadImage($event)"
                        />
                      </div>
                      <div class="col-lg-6">
                        <img
                          id="site-logo"
                          :src="$getLogo()"
                          alt="logo"
                          style="
                            text-align: center;
                            border: 3px solid #867df2;
                            border-radius: 8px;
                            padding: 30px;
                            max-height: 120px;
                            width: 100%;
                          "
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6">
                  <div class="fom-group">
                    <label for="">System Currency</label>
                    <select
                      v-model="form.system_curency"
                      class="form-control"
                      name="system_currency"
                    >
                      <option value="TAKA">Bangladeshi Taka</option>
                      <option value="USA Dolar">USA Dolar</option>
                    </select>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="fom-group">
                    <label for="">Select Country</label>
                    <select
                      v-model="form.country"
                      class="form-control"
                      name="system_currency"
                    >
                      <option value="Bangladesh">Bangladesh</option>
                      <option value="USA">USA</option>
                    </select>
                  </div>
                </div>
                <br />
                <div class="col-lg-6 mt-1">
                  <div class="fom-group">
                    <label for="">Select attendance Type</label>
                    <select
                      v-model="form.attendance_type"
                      class="form-control"
                      name="attendance_type"
                    >
                      <option value="biometric">Biometric</option>
                      <option value="mannual">Mannual</option>
                    </select>
                  </div>
                </div>

                <div class="col-lg-6 mt-1">
                  <div class="fom-group">
                    <label for="">Company Name</label>
                    <input
                      v-model="form.welcome_message"
                      type="text"
                      class="form-control"
                      name="welcome_message"
                    />
                  </div>
                </div>
                <div class="col-lg-6 mt-1">
                  <div class="fom-group">
                    <label for="">Company Address</label>
                    <input
                      v-model="form.company_address"
                      type="text"
                      class="form-control"
                      name="welcome_message"
                    />
                  </div>
                </div>

                <div class="col-lg-6 mt-1">
                  <div class="fom-group">
                    <label for="">Company Email</label>
                    <input
                      v-model="form.company_email"
                      type="text"
                      class="form-control"
                      name="welcome_message"
                    />
                  </div>
                </div>
                <div class="col-lg-6 mt-1">
                  <div class="fom-group">
                    <label for="">Company Website</label>
                    <input
                      v-model="form.company_website"
                      type="text"
                      class="form-control"
                      name="welcome_message"
                    />
                  </div>
                </div>
                <div class="col-lg-6 mt-1">
                  <div class="fom-group">
                    <label for="">Company Phone</label>
                    <input
                      v-model="form.company_phone"
                      type="text"
                      class="form-control"
                      name="welcome_message"
                    />
                  </div>
                </div>
                <div class="col-lg-6 mt-1">
                  <div class="fom-group">
                    <label for="">Company Helpline</label>
                    <input
                      v-model="form.company_help_line"
                      type="text"
                      class="form-control"
                      name="welcome_message"
                    />
                  </div>
                </div>
                <div class="col-lg-6 mt-1">
                  <div class="fom-group">
                    <label for="">Company WeekDays</label>
                    <input
                      v-model="form.company_week_days"
                      type="text"
                      class="form-control"
                      name="welcome_message"
                    />
                  </div>
                </div>
                <div class="col-lg-6 mt-1">
                  <div class="fom-group">
                    <label for="">Company Weeknnd</label>
                    <input
                      v-model="form.company_weekend"
                      type="text"
                      class="form-control"
                      name="welcome_message"
                    />
                  </div>
                </div>
                <div class="col-lg-6 mt-1">
                  <div class="fom-group">
                    <label for="">Comapny Facebook Link</label>
                    <input
                      v-model="form.company_facebook_link"
                      type="text"
                      class="form-control"
                      name="welcome_message"
                    />
                  </div>
                </div>
                <div class="col-lg-6 mt-1">
                  <div class="fom-group">
                    <label for="">Comapny Twiiter Link</label>
                    <input
                      v-model="form.company_twitter_link"
                      type="text"
                      class="form-control"
                      name="welcome_message"
                    />
                  </div>
                </div>
                <div class="col-lg-6 mt-1">
                  <div class="fom-group">
                    <label for="">User Reference Number Start</label>
                    <input
                      v-model="form.user_reference_number_start"
                      type="text"
                      class="form-control"
                      name="welcome_message"
                    />
                  </div>
                </div>
                <div class="col-lg-6 mt-1">
                  <div class="fom-group">
                    <label for="">Time Zone</label>

                    <v-select
                      v-model="form.time_zone"
                      :options="time_zone_options"
                    />
                  </div>
                </div>
                <div class="col-lg-6 mt-1">
                  <div class="fom-group">
                    <label for="Copyriht-Left">Copyriht Left</label>
                    <textarea
                      class="form-control"
                      name=""
                      id="Copyriht-Left"
                      v-model="form.copyright_left"
                      placeholder="Copyriht Left"
                      rows="2"
                    ></textarea>
                  </div>
                </div>
                <div class="col-lg-6 mt-1">
                  <div class="fom-group">
                    <label for="">Copyriht Right</label>
                    <textarea
                      class="form-control"
                      name=""
                      id="Copyriht-right"
                      placeholder="Copyriht Right"
                      v-model="form.copyright_right"
                      rows="2"
                    ></textarea>
                  </div>
                </div>
                <div class="col-lg-6 mt-1">
                  <div class="fom-group">
                    <label for="">Google Map API Key</label>
                    <input
                      type="text"
                      class="form-control"
                      name="google_map_api_key"
                      id="google_map_api_key"
                      placeholder="e.g. AIzaSy..."
                      v-model="form.google_map_api_key"
                    />
                  </div>
                </div>
              </div>
              <div class="row mt-1 justify-content-center">
                <div class="col-lg-6">
                  <button type="submit" class="btn btn-primary btn-block">
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </b-card-body>
    </b-card-code>
  </div>
</template>

<script>
import Form from "vform";
import vSelect from "vue-select";
import BCardCode from "@core/components/b-card-code/BCardCode";

export default {
  name: "GeneralSetting",
  components: {
    vSelect,
    BCardCode,
  },
  data() {
    return {
      title: "General Setting",
      form: new Form({
        site_name: "",
        system_curency: "",
        country: "",
        welcome_message: "",
        company_address: "",
        company_email: "",
        company_website: "",
        company_phone: "",
        company_help_line: "",
        company_week_days: "",
        company_weekend: "",
        company_facebook_link: "",
        company_twitter_link: "",
        user_reference_number_start: "",
        site_logo: "",
        copyright_left: "",
        copyright_right: "",
        time_zone: {
          code: "",
          label: "",
        },
        google_map_api_key: "",
      }),
      site_logo: null,
      loading: true,
      time_zones: [],
      time_zone_options: [],
    };
  },
  computed: {
    general_setting() {
      return this.$store.state.isp.general_setting;
    },
  },

  watch: {
    general_setting(value) {
      if (Object.keys(value).length > 0) {
        this.form.site_name = value.site_name;
        this.form.system_curency = value.system_curency;
        this.form.country = value.country || "";
        this.form.attendance_type = value.attendance_type || "";
        this.form.welcome_message = value.welcome_message || "";
        this.form.company_email = value.company_email || "";
        this.form.company_website = value.company_website || "";
        this.form.company_help_line = value.company_help_line || "";
        this.form.company_weekend = value.company_weekend || "";
        this.form.company_facebook_link = value.company_facebook_link || "";
        this.form.company_twitter_link = value.company_twitter_link || "";
        this.form.user_reference_number_start =
          value.user_reference_number_start || "";
        this.form.copyright_left = value.copyright_left;
        this.form.copyright_right = value.copyright_right;
        this.form.time_zone.code = value.time_zone;
        this.form.time_zone.label = value.time_zone;
        this.loading = false;
        if (value.google_map_api_key) {
          this.form.google_map_api_key = value.google_map_api_key;
        }
        if (value.time_zones && value.time_zones.length > 0) {
          value.time_zones.forEach((element) => {
            const obj = {
              code: element,
              label: element,
            };
            this.time_zone_options.push(obj);
          });
        }
      } else {
        console.log(value);
      }
    },
  },
  created() {
    this.$store.dispatch("general_setting", {
      get_time_zone: true,
    });
  },
  methods: {
    uploadImage($event) {
      const file = $event.target.files[0];
      if (!file.type.match("image.*")) {
        alert("This is not any kind of image");
        return;
      }
      this.form.site_logo = file;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (evt) => {
        const img = new Image();
        img.onload = () => {
          document.getElementById("site-logo").src = evt.target.result;
        };
        img.src = evt.target.result;
      };
    },
    UpdateGeneralSetting() {
      //  this.$form_submit()

      this.form.post("general/setting").then((resp) => {
        // this.$form_submit(false)

        if (resp.data.success) {
          this.$s_tost(resp.data.message);
          if (typeof this.form.google_map_api_key !== "undefined") {
            this.$store.commit(
              "app/UPDATE_GOOGLE_MAP_KEY",
              this.form.google_map_api_key
            );
          }
          window.location.reload();
        } else {
          this.$error_message(resp.data.message, resp.data.errors);
        }
      });
    },
  },
};
</script>
